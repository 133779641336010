import React, { SetStateAction, useEffect } from 'react'
import { FreeButton } from 'react-ui-scaffold'
import Popup from '../../Components/Popup/Popup'
import './ConfirmDeletePopup.css'

interface ConfirmDeletePopupProps {
	view: string,
	error?: string,
    visible: boolean,
    setVisible: React.Dispatch<SetStateAction<boolean>>,
    onComplete?: () => void,
	onDelete: () => void,
	confirmContent: React.ReactNode,
	confirmedContent: React.ReactNode,
}

export default function ConfirmDeletePopup({ view, error, visible, setVisible, onComplete, onDelete, confirmContent, confirmedContent }: ConfirmDeletePopupProps) {

	useEffect(() => {
		if (!visible) {
			if (view == 'confirmed') {
				onComplete && onComplete()
			}
		}
	}, [visible])

	if (!visible) {
		return null
	}

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title={view == 'delete confirm' ? 'Delete Item?' : 'Item Deleted'}
			className='ConfirmDeletePopup thinContainer'
		>
			<div className="story error">
				{error}
			</div>

			<div className="story body">
				{view == 'delete confirm' ? (
					<>
						<div>
							{confirmContent}
						</div>
						<div className="action">
							<FreeButton className="hollow" onClick={() => onDelete()}>Yes, Delete Item</FreeButton>
							<FreeButton onClick={() => setVisible(false)}>Cancel</FreeButton>
						</div>
					</>
				) : (
					<>
						<div>
							{confirmedContent}
						</div>
						<div className="action">
							<FreeButton className="hollow" onClick={() => setVisible(false)}>Back</FreeButton>
						</div>
					</>
				)}
			</div>

		</Popup>
	)
}
