export enum DiscountStatuses {
	Active = 'Active',
	Inactive = 'Inactive'
}

export enum DiscountUsageOptions {
	Unlimited = 'Unlimited',
	OncePerAccount = 'Once per Account'
}

export interface Discount {
    code: string;
    amount: number;
    type: string;
    status: string;
    discountID: number;
	usage: string;
	products: number[];
}
