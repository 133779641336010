import React, { useState, SetStateAction, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { archiveProductSku, ProductSku } from '../../../Redux/Slices/productsSlice'
import ConfirmDeletePopup from '../../ConfirmDeletePopup/ConfirmDeletePopup'
import './ConfirmSkuDeletePopup.css'

interface ConfirmDeletePopupProps {
    visible: boolean
    setVisible: React.Dispatch<SetStateAction<boolean>>
	sku: ProductSku
}

export default function ConfirmSkuDeletePopup({ sku, visible, setVisible }: ConfirmDeletePopupProps) {
	const dispatch = useDispatch()

	const [error, setError] = useState(undefined)
	const [view, setView] = useState('')
	const activeProduct = useSelector((s: any) => s.product.activeProduct)

	useEffect(() => {
		if (visible) {
			setError(undefined)
			setView('delete confirm')
		}
	}, [visible])

	const onDelete = () => {
		dispatch(archiveProductSku({
			productSkuID: sku.productSkuID,
			callback: (err: any) => {
				if (err) {
					return setError(err)
				}
				setView('confirmed')
			}
		}))
	}

	if (!visible) {
		return null
	}

	return (
		<ConfirmDeletePopup
			view={view}
			error={error}
			visible={visible}
			setVisible={setVisible}
			onDelete={onDelete}
			confirmContent={(
				<div>
					Are you sure you want to delete the size&nbsp;
					<span className="BoldName">{sku.name}</span>&nbsp;
					from <span className="BoldName">{activeProduct.name}</span>?
				</div>
			)}
			confirmedContent={(
				<div>
					The size <span className="BoldName">{sku.name}</span>&nbsp;
					has been deleted.
				</div>
			)}
		/>
	)
}
