import React, { useState, SetStateAction, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Popup from '../../../../../Components/Popup/Popup'
import { Customer } from '../../../../../Redux/Slices/customerSlice'
import { fetchCustomersByDiscount } from '../../../../../Redux/Slices/discountSlice'

import './DiscountCustomersPopup.css'

interface DiscountCustomersPopupProps {
	visible: boolean
	setVisible: React.Dispatch<SetStateAction<boolean>>
}

const pageSize = 50

export default function DiscountCustomersPopup({ visible, setVisible }: DiscountCustomersPopupProps) {
	const dispatch = useDispatch()
	const history = useHistory()
	const [page, setPage] = useState(1)
	const [lastFetchedPage, setLastFetchedPage] = useState(0)

	const _fetchCustomers = () => {
		dispatch(fetchCustomersByDiscount({ discountID: activeDiscount.discountID, page }))
		setLastFetchedPage(page)
	}
	
	const activeDiscount = useSelector((s: any) => s.discount.activeDiscount)
	useEffect(() => {
		if (visible && lastFetchedPage != page && activeDiscount.discountID) {
			_fetchCustomers()
		}
	}, [visible, activeDiscount.discountID, page])

	useEffect(() => {
		if (activeDiscount.discountID) {
			setPage(1)
		}
	}, [activeDiscount.discountID])
	
	if (!visible) {
		return null
	}

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title={`Customers - ${activeDiscount.code}`}
			className='DiscountCustomersPopup'
		>
			
			<div className="story body">
				<div className="CustomerCount hemi">{activeDiscount.customerCount} Customers</div>
				<table className='wide CustomersTable'>
					<thead>
						<tr>
							<th>Customer Name:</th>
							<th>Email:</th>
							<th>Phone Number:</th>
						</tr>
					</thead>
					<tbody>
						{activeDiscount.customers?.map((customer: Customer) => {
							return (
								<tr key={customer.playerEmail}>
									<td>
										<div
											className="button neutral plain"
											onClick={() => history.push(`/Customers/${customer.playerEmail}`)}
										>
											{customer.playerFirstName} {customer.playerLastName}
										</div>
									</td>
									<td>{customer.playerEmail}</td>
									<td>{customer.phone}</td>
								</tr>
							)
						})}
					</tbody>
				</table>
				{pageSize * page < (activeDiscount?.customerCount || 0) ? (
					<div className="LoadMore" onClick={() => setPage(page + 1)}>Load more</div>
				) : null}
			</div>
			
		</Popup>
	)
}
