import React, { useState, SetStateAction, useEffect, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { FreeButton } from 'react-ui-scaffold'
import Popup from '../../../Components/Popup/Popup'

import './AddDiscountPopup.css'
import { createDiscount } from '../../../Redux/Slices/discountSlice'
import { DiscountUsageOptions } from '../../../@types/Discount'
import AppliestoProductsList from '../Support/AppliesToProductsList'

interface AddDiscountPopupProps {
	visible: boolean
	setVisible: React.Dispatch<SetStateAction<boolean>>
}

const defaultData = {
	status: 'Active',
	type: 'Percent',
	usage: DiscountUsageOptions.Unlimited,
	products: []
}

export default function AddDiscountPopup({ visible, setVisible }: AddDiscountPopupProps) {
	const dispatch = useDispatch()
	
	const [error, setError] = useState(null)
	const [data, setData] = useState<any>(defaultData)
	const [loading, setLoading] = useState<boolean>(false)

	useEffect(() => {
		if (visible) {
			setData(defaultData)
			setLoading(false)
			setError(null)
		}
	}, [visible])

	const changeHandler = (e: ChangeEvent<HTMLInputElement| HTMLSelectElement>) => {
		setData({ ...data, [e.target.name]: e.target.value })
	}
	
	const onSubmit = () => {
		setLoading(true)
		const amount = (parseFloat(data.amount) * (data.type == 'Percent' ? 1 : 100)).toFixed(0)
		dispatch(createDiscount({
			data: {
				...data,
				amount
			},
			callback: (err: any) => {
				setLoading(false)
				if (err) {
					return setError(err)
				}
				setVisible(false)
			}
		}))
	}

	if (!visible) {
		return null
	}

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title='Add Code'
			className='AddDiscountPopup thinContainer'
		>
			<div className="story error">
				{error}
			</div>
			
			<div className="story body">
				<div className="subStory" >
					<label>Code Name:</label>
					<input
						type="text"
						name="code"
						className="input wide"
						value={data.code || ''}
						onChange={changeHandler}
						placeholder="eg. SAVE10"
					/>
				</div>
				<div className="subStory" >
					<label>Discount:</label>
					<div className="AmountContainer wide">
						<div className="AmountInput" onClick={() => setData({ ...data, type: 'Percent' })}>
							<input
								type="radio"
								checked={data.type == 'Percent'}
								readOnly
							/>
							<input
								type="number"
								name='amount'
								className="input amountInput"
								value={data.amount || ''}
								onChange={changeHandler}
								onBlur={(e: any) => {
									setData({
										...data,
										[e.target.name]: (Math.round((parseFloat(e.target.value) + Number.EPSILON)* 100) / 100).toFixed(2)
									})
									
								}}
							/>
							% off
						</div>
						<div className="AmountInput" onClick={() => setData({ ...data, type: 'Dollar' })}>
							<input
								type="radio"
								checked={data.type == 'Dollar'}
								readOnly
							/>
							$
							<input
								type="number"
								name='amount'
								className="input amountInput"
								value={data.amount || ''}
								onChange={changeHandler}
								onBlur={(e: any) => {
									setData({
										...data,
										[e.target.name]: (Math.round((parseFloat(e.target.value) + Number.EPSILON)* 100) / 100).toFixed(2)
									})
									
								}}
							/>
							CAD
						</div>
					</div>
				</div>
				<AppliestoProductsList
					data={data}
					setData={setData}
				/>
				<div className="subStory" >
					<label>Usage:</label>
					<select
						name='usage'
						className='input wide'
						value={data.usage || ''}
						onChange={changeHandler}
					>
						<option value={DiscountUsageOptions.Unlimited}>
							{DiscountUsageOptions.Unlimited}
						</option>
						<option value={DiscountUsageOptions.OncePerAccount}>
							{DiscountUsageOptions.OncePerAccount}
						</option>
					</select>
				</div>
				<div className="subStory" >
					<label>Status:</label>
					<select
						name='status'
						className='input wide'
						value={data.status || ''}
						onChange={changeHandler}
					>
						<option value="Active">
							Active
						</option>
						<option value="Inactive">
							Inactive
						</option>
					</select>
				</div>
			</div>
			<div className="DoneButtonContainer">
				<FreeButton
					className="DoneButton"
					onClick={() => onSubmit()}
					loading={loading}
				>
					Done
				</FreeButton>
			</div>
			
		</Popup>
	)
}
