import React, { useState, SetStateAction, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteProduct } from '../../../Redux/Slices/productsSlice'
import ConfirmDeletePopup from '../../ConfirmDeletePopup/ConfirmDeletePopup'
import './ConfirmProductDeletePopup.css'

interface ConfirmDeletePopupProps {
    visible: boolean
    setVisible: React.Dispatch<SetStateAction<boolean>>
    onComplete: () => void
}

export default function ConfirmProductDeletePopup({ visible, setVisible, onComplete }: ConfirmDeletePopupProps) {
	const dispatch = useDispatch()

	const [error, setError] = useState(undefined)
	const [view, setView] = useState('')
	const activeProduct = useSelector((s: any) => s.product.activeProduct)

	useEffect(() => {
		if (visible) {
			setError(undefined)
			setView('delete confirm')
		}
	}, [visible])

	const onDelete = () => {
		dispatch(deleteProduct({
			productID: activeProduct.productID,
			callback: (err: any) => {
				if (err) {
					return setError(err)
				}
				setView('confirmed')
			}
		}))
	}

	if (!visible) {
		return null
	}

	return (
		<ConfirmDeletePopup
			view={view}
			error={error}
			visible={visible}
			setVisible={setVisible}
			onComplete={onComplete}
			onDelete={onDelete}
			confirmContent={(
				<div>
					Are you sure you want to remove the item&nbsp;
					<span className="ProductName">{activeProduct.name}</span>&nbsp;from the items list?
				</div>
			)}
			confirmedContent={(
				<div>
					<span className="ProductName">{activeProduct.name}</span>&nbsp;
					has been delete from the items list. You can find it in the Deleted items page.
				</div>
			)}
		/>
	)
}
