import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Product } from '../../../Redux/Slices/productsSlice'
import './AppliestoProductsList.css'

export default function AppliestoProductsList({ data, setData }: { data: any, setData: (v: any) => void }) {
	const products: Product[] = useSelector((s: any) => s.product.list)
	const setAllProductsSelected = () => {
		setData({ ...data, products: products.map(({ productID }) => productID) })
	}
	useEffect(setAllProductsSelected, [])

	const [selectedProductsMap, allSelected] = useMemo(() => {
		const map: any = {}
		data.products?.map((productID: number) => {
			map[productID] = true
		})
		return [
			map,
			Object.keys(map).length == products.length
		]
	}, [data])

	const onProductClick = (productID: number) => {
		if (selectedProductsMap[productID]) {
			delete selectedProductsMap[productID]
		} else {
			selectedProductsMap[productID] = true
		}
		setData({ ...data, products: Object.keys(selectedProductsMap) })
	}

	const onAllItemsClick = () => {
		if (allSelected) {
			setData({ ...data, products: [] })
		} else {
			setAllProductsSelected()
		}
	}
	
	
	return (
		<div className="subStory AppliestoProductsList">
			<label>Applies to:</label>
			<div className="ProductList">
				<div className="CheckboxLabel" onClick={onAllItemsClick}>
					<input
						type="checkbox"
						checked={allSelected || !data.products.length}
						readOnly
					/>
					<span>All Items</span>
				</div>
				{products.map((product) => {
					return (
						<div
							className="CheckboxLabel"
							key={product.productID}
							onClick={() => onProductClick(product.productID)}
						>
							<input
								type="checkbox"
								checked={Boolean(selectedProductsMap[product.productID])}
								readOnly
							/>
							<span className="Label">{product.name}</span>
						</div>
					)

				})}
			</div>
		</div>
	)
}
