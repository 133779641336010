import React, { ChangeEvent } from 'react'
import { DiscountStatuses, DiscountUsageOptions } from '../../../../@types/Discount'
import AppliestoProductsList from '../../Support/AppliesToProductsList'

export default function DiscountFullEditForm({ data, setData, changeHandler }: { data: any, setData: (v: any) => void, changeHandler: (v: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void}) {
	return (
		<div className="story body">
			<div className="subStory" >
				<label>Code Name:</label>
				<input
					type="text"
					name="code"
					className="input wide"
					value={data.code || ''}
					onChange={changeHandler}
					placeholder="eg. SAVE10"
				/>
			</div>
			<div className="subStory" >
				<label>Discount:</label>
				<div className="AmountContainer wide">
					<div className="AmountInput" onClick={() => setData({ ...data, type: 'Percent' })}>
						<input
							type="radio"
							checked={data.type == 'Percent'}
							readOnly
						/>
						<input
							type="number"
							name='amount'
							className="input amountInput"
							value={data.amount || ''}
							onChange={changeHandler}
							onBlur={(e: any) => {
								setData({
									...data,
									[e.target.name]: (Math.round((parseFloat(e.target.value) + Number.EPSILON)* 100) / 100).toFixed(2)
								})
								
							}}
						/>
						% off
					</div>
					<div className="AmountInput" onClick={() => setData({ ...data, type: 'Dollar' })}>
						<input
							type="radio"
							checked={data.type == 'Dollar'}
							readOnly
						/>
						$
						<input
							type="number"
							name='amount'
							className="input amountInput"
							value={data.amount || ''}
							onChange={changeHandler}
							onBlur={(e: any) => {
								setData({
									...data,
									[e.target.name]: (Math.round((parseFloat(e.target.value) + Number.EPSILON)* 100) / 100).toFixed(2)
								})
								
							}}
						/>
						CAD
					</div>
				</div>
			</div>
			<AppliestoProductsList
				data={data}
				setData={setData}
			/>
			<div className="subStory" >
				<label>Usage:</label>
				<select
					name='usage'
					className='input wide'
					value={data.usage || ''}
					onChange={changeHandler}
				>
					<option value={DiscountUsageOptions.Unlimited}>
						{DiscountUsageOptions.Unlimited}
					</option>
					<option value={DiscountUsageOptions.OncePerAccount}>
						{DiscountUsageOptions.OncePerAccount}
					</option>
				</select>
			</div>
			<div className="subStory" >
				<label>Status:</label>
				<select
					name='status'
					className='input wide'
					value={data.status || ''}
					onChange={changeHandler}
				>
					<option value={DiscountStatuses.Active}>
						{DiscountStatuses.Active}
					</option>
					<option value={DiscountStatuses.Inactive}>
						{DiscountStatuses.Inactive}
					</option>
				</select>
			</div>
		</div>
	)
}
