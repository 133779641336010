import React from 'react'
import { useSelector } from 'react-redux'
import { Order } from '../../../../../Redux/Slices/orderSlice'
import CollapsibleCard from '../../../../../Components/CollapsibleCard/CollapsibleCard'
import LabelText from '../../../../../Components/LabelText/LabelText'
import './CustomerInfo.css'

export default function CustomerInfo({ order }: { order: Order }) {
	const { activeLocation = {} } = useSelector((s: any) => s.activeUser)
	return (
		<div className='CustomerInfo'>
			<CollapsibleCard
				expandedContent={
					<>
						<LabelText
							label="Email"
							text={order.placedBy}
						/>
						<LabelText
							label="Phone"
							text={order.contactPhone}
						/>
						<LabelText
							label="User Name"
							text={`${order.contactFirstName} ${order.contactLastName}`}
						/>
						<LabelText
							label="Subtotal"
							text={`$${(order.total / 100).toFixed(2)}`}
						/>
						{ order.discountTotal ?
							<>
								<LabelText
									label="Discount Code"
									className="discount"
									text={`${order.discountCode}`}
								/>
								<LabelText
									label="Discount"
									className="discount"
									text={`- $${(order.discountTotal / 100).toFixed(2)}`}
								/>
							</> :
							null
						}
						<LabelText
							label="Taxes"
							text={`$${(order.tax / 100).toFixed(2)}`}
						/>
						<LabelText
							label="Final Total"
							text={`$${(order.finalTotal / 100).toFixed(2)}`}
						/>
						<LabelText
							label="Store Location"
							text={`${activeLocation.locationID} - ${activeLocation.title}`}
						/>
					</>
				}
			>
                Order/Customer Info
			</CollapsibleCard>
		</div>
	)
}
