import React, { useState, SetStateAction, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteDiscount } from '../../../Redux/Slices/discountSlice'
import ConfirmDeletePopup from '../../ConfirmDeletePopup/ConfirmDeletePopup'
import './ConfirmDiscountDeletePopup.css'

interface ConfirmDeletePopupProps {
    visible: boolean
    setVisible: React.Dispatch<SetStateAction<boolean>>
    onComplete: () => void
}

export default function ConfirmDiscountDeletePopup({ visible, setVisible, onComplete }: ConfirmDeletePopupProps) {
	const dispatch = useDispatch()

	const [error, setError] = useState(undefined)
	const [view, setView] = useState('')
	const activeDiscount = useSelector((s: any) => s.discount.activeDiscount)

	useEffect(() => {
		if (visible) {
			setError(undefined)
			setView('delete confirm')
		}
	}, [visible])

	const onDelete = () => {
		dispatch(deleteDiscount({
			discountID: activeDiscount.discountID,
			callback: (err: any) => {
				if (err) {
					return setError(err)
				}
				setView('confirmed')
			}
		}))
	}

	if (!visible) {
		return null
	}

	return (
		<ConfirmDeletePopup
			view={view}
			error={error}
			visible={visible}
			setVisible={setVisible}
			onComplete={onComplete}
			onDelete={onDelete}
			confirmContent={(
				<div>
					Are you sure you want to delete the discount code&nbsp;
					<span className="DiscountCode">{activeDiscount.code}</span>?
				</div>
			)}
			confirmedContent={(
				<div>
					The discount code&nbsp;
					<span className="DiscountCode">{activeDiscount.code}</span>&nbsp;
					has been deleted.
				</div>
			)}
		/>
	)
}
