import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MainContainer from '../../Components/MainContainer/MainContainer'
import './Products.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { fetchActiveProducts, fetchDeletedProducts, Product } from '../../Redux/Slices/productsSlice'
import DeleteProductsPopup from '../../Popups/ProductPopups/DeletedProductsPopup/DeleteProductsPopup'
import ProductCard from './Support/ProductCard/ProductCard'
import AddProductPopup from '../../Popups/ProductPopups/AddProductPopup/AddProductPopup'
import ConfirmDeletePopup from '../../Popups/ProductPopups/ConfirmProductDeletePopup/ConfirmProductDeletePopup'
import { fetchAllBasicPresets } from '../../Redux/Slices/basicPresetSlices'
import ManageBasicPresetsPopup from '../../Popups/ManageBasicPresetsPopup/ManageBasicPresetsPopup'
import { useHistory } from 'react-router-dom'

export default function Products() {
	const dispatch = useDispatch()
	const history = useHistory()

	const [search, setSearch] = useState('')

	const products: Product[] = useSelector((s: any) => s.product.list)
	const { role } = useSelector((s: any) => s.activeUser.data)
	const isOwner = role =='owner'

	const [deletedProductsVisible, setDeletedProductsVisible] = useState(false)
	const [addProductVisible, setAddProductVisible] = useState(false)
	const [deletePopupOpen, setDeletePopupOpen] = useState<boolean>(false)
	const [basicPresetPopupOpen, setBasicPresetPopupOpen] = useState<boolean>(false)

	useEffect(() => {
		dispatch(fetchActiveProducts({}))
		dispatch(fetchDeletedProducts())
		dispatch(fetchAllBasicPresets())
	}, [])

	useEffect(() => {
		dispatch(fetchActiveProducts({ search }))
	}, [search])

	return (
		<div className="Products">
			<DeleteProductsPopup
				visible={deletedProductsVisible}
				setVisible={setDeletedProductsVisible}
			/>
			<AddProductPopup
				visible={addProductVisible}
				setVisible={setAddProductVisible}
			/>
			<ConfirmDeletePopup
				visible={deletePopupOpen}
				setVisible={setDeletePopupOpen}
				onComplete={() => {
					setDeletePopupOpen(false)
				}}
			/>
			<ManageBasicPresetsPopup
				visible={basicPresetPopupOpen}
				setVisible={setBasicPresetPopupOpen}
			/>
			<MainContainer>
				<div className="flexY header">
					<h1 className='title'>
						Skatescribe Products
					</h1>
					<div className="SearchBox">
						<FontAwesomeIcon icon={faSearch} color="#b7b7b7" className="SearchIcon" />
						<input
							type="text"
							className="input SearchInput"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							placeholder="Search for a product name or SKU"
						/>
					</div>
				</div>
				<div className="ProductContainer">
					{products.map((product) => {
						return <ProductCard
							key={product.productID}
							product={product}
							onDelete={() => setDeletePopupOpen(true)}
						/>
					})}
				</div>
				<div className="ActionContainer">
					<button className="button neutral plain viewAll" onClick={() => history.push('/Discounts')}>
						Discount Codes
					</button>
					{ isOwner ? (
						<button className="button neutral plain viewAll" onClick={() => setAddProductVisible(true)}>
							+Add a Product
						</button>
					) : null}
					<button className="button neutral plain viewAll" onClick={() => setBasicPresetPopupOpen(true)}>
						Basic Presets Specs
					</button>
					<button className="button neutral plain viewAll" onClick={() => setDeletedProductsVisible(true)}>
						Deleted Items
					</button>
				</div>
			</MainContainer>
		</div>
	)
}
