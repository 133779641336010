import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Discount } from '../../@types/Discount'
import beam from '../../Handlers/beam'
import { Order } from './orderSlice'

export const fetchActiveDiscounts = createAsyncThunk('discounts/fetchActiveDiscounts', async ({ search }: { search?: string }) => {
	const discountRes = await beam.get(`/api/admin/fetchActiveDiscounts${search ? `?search=${search}`: ''}`)
	return { discounts: discountRes.response.data }
})

export const createDiscount = createAsyncThunk('discounts/createDiscount', async ({ data, callback }: { data: any, callback?: Function }) => {
	const discountCreateRes = await beam.post('/api/admin/createDiscount', data).catch(({ response }) => {
		callback && callback(response.error)
		return Promise.reject('Unable to create discount!')
	})
	callback && callback()
	return { data: discountCreateRes.response.data }
})

export const updateDiscount = createAsyncThunk('discounts/updateDiscount', async ({ data, callback }: { data: any, callback?: Function }) => {
	const discountUpdateRes = await beam.post('/api/admin/updateDiscount', data).catch(({ response }) => {
		callback && callback(response.error)
		return Promise.reject('Unable to update discount!')
	})
	callback && callback()
	return { data: discountUpdateRes.response.data }
})

export const updateDiscountStatus = createAsyncThunk('discounts/updateDiscountStatus', async ({ data, callback }: { data: any, callback?: Function }) => {
	const productUpdateRes = await beam.post('/api/admin/updateDiscountStatus', data).catch(({ response }) => {
		callback && callback(response.error)
		return Promise.reject('Unable to update discount!')
	})
	callback && callback()
	return { data: productUpdateRes.response.data }
})

export const deleteDiscount = createAsyncThunk('discounts/deleteDiscount', async ({ discountID, callback }: { discountID: any, callback?: Function }) => {
	const discountDeleteRes = await beam.post('/api/admin/archiveDiscount', { discountID }).catch(({ response }) => {
		callback && callback(response.error)
		return Promise.reject('Unable to delete discount!')
	})
	callback && callback()
	return { data: discountDeleteRes.response.data }
})

export const fetchDiscount = createAsyncThunk('discounts/fetchDiscount', async ({ discountID }: { discountID: number }) => {
	const discountRes = await beam.post('/api/admin/fetchDiscount', { discountID })
	return { data: discountRes.response.data }
})

export const fetchOrdersByDiscount = createAsyncThunk('discounts/fetchOrdersByDiscount', async ({ page, discountID }: { page: number, discountID: number }) => {
	const discountOrdersRes = await beam.get(`/api/admin/fetchOrdersByDiscount/${discountID}?page=${page}`, { discountID, page })
	return { data: discountOrdersRes.response.data }
})

export const fetchCustomersByDiscount = createAsyncThunk('discounts/fetchCustomersByDiscount', async ({ page, discountID }: { page: number, discountID: number }) => {
	const discountCustomersRes = await beam.get(`/api/admin/fetchCustomersByDiscount/${discountID}?page=${page}`, { discountID, page })
	return { data: discountCustomersRes.response.data }
})

const updateProductFulfilled = (state: any, { payload }: any) => {
	const { data }: { data: Discount } = payload
	state.activeDiscount = { ...state.activeDiscount, ...data }
	const activeIndex = state.list.findIndex((discount: Discount) => discount.discountID == data.discountID)
	if (activeIndex != -1) {
		const newList = [...state.list]
		newList[activeIndex] = { ...newList[activeIndex], ...data }
		state.list = newList
	}
}

const discountList: Discount[] = []
const discountsSlice = createSlice({
	name: 'discounts',
	initialState: {
		list: discountList,
		activeDiscount: null
	},
	reducers: {
		setActiveDiscount: (state: any, action: any) => {
			state.activeDiscount = action.payload
		},
		clearActiveDiscount: (state: any) => {
			state.activeDiscount = null
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchActiveDiscounts.fulfilled, (state: any, { payload }: any) => {
			const { discounts = [] }: { discounts: Discount[] } = payload
			state.list = discounts
		})
		builder.addCase(createDiscount.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: Discount } = payload
			state.list = [...state.list, data]
		})
		builder.addCase(updateDiscount.fulfilled, updateProductFulfilled)
		builder.addCase(updateDiscountStatus.fulfilled, updateProductFulfilled)
		builder.addCase(deleteDiscount.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: Discount } = payload
			const activeIndex = state.list.findIndex((discount: Discount) => discount.discountID == data.discountID)
			const newList = [...state.list]
			newList.splice(activeIndex, 1)
			state.list = newList
		})
		builder.addCase(fetchDiscount.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: Discount } = payload
			state.activeDiscount = data
		})
		builder.addCase(fetchOrdersByDiscount.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: Order[] } = payload
			state.activeDiscount = { ...state.activeDiscount, orders: [...(state.activeDiscount.orders || []), ...data] }
		})
		builder.addCase(fetchCustomersByDiscount.fulfilled, (state: any, { payload }: any) => {
			const { data }: { data: Order[] } = payload
			state.activeDiscount = { ...state.activeDiscount, customers: [...(state.activeDiscount.customers || []), ...data] }
		})
	}
})

export const { setActiveDiscount, clearActiveDiscount } = discountsSlice.actions
export default discountsSlice.reducer
