import React from 'react'

export default function LabelText({ label, text, className = '' }: { label: string, text: string, className?: string }) {
	return (
		<div className={className}>
			<span className="Label">
				{label}:
			</span>
			<span className="Value">
				{text}
			</span>
		</div>
	)
}
