import { configureStore } from '@reduxjs/toolkit'
import activeUser from './Slices/activeUserSlice'
import view from './Slices/viewSlice'
import order from './Slices/orderSlice'
import employee from './Slices/employeeSlice'
import product from './Slices/productsSlice'
import basicPreset from './Slices/basicPresetSlices'
import customer from './Slices/customerSlice'
import discount from './Slices/discountSlice'

export const store = configureStore({
	reducer: {
		activeUser,
		view,
		order,
		employee,
		product,
		basicPreset,
		customer,
		discount
	}
})
