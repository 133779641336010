import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Discount } from '../../@types/Discount'
import MainContainer from '../../Components/MainContainer/MainContainer'
import AddDiscountPopup from '../../Popups/DiscountPopups/AddDiscountPopup/AddDiscountPopup'
import ConfirmDeletePopup from '../../Popups/DiscountPopups/ConfirmDiscountDeletePopup/ConfirmDiscountDeletePopup'
import { fetchActiveDiscounts } from '../../Redux/Slices/discountSlice'
import { fetchActiveProducts, fetchDeletedProducts, Product } from '../../Redux/Slices/productsSlice'
import './Discounts.css'
import DiscountCard from './Support/DiscountCard/DiscountCard'

export default function Discounts() {
	const dispatch = useDispatch()

	const [search, setSearch] = useState('')
	const [addPopupOpen, setAddPopupOpen] = useState<boolean>(false)
	const [deletePopupOpen, setDeletePopupOpen] = useState<boolean>(false)
	const products: Product[] = useSelector((s: any) => s.product.list)
	const deletedProducts: Product[] = useSelector((s: any) => s.product.deletedList)

	const productsMap = useMemo(() => {
		const map: { [key: string]: { deleted: boolean, product: Product } } = {}
		products.map((product) => {
			map[product.productID] = { deleted: false, product }
		})
		deletedProducts.map((product) => {
			map[product.productID] = { deleted: true, product }
		})
		return map
	}, [products, deletedProducts])

	useEffect(() => {
		dispatch(fetchActiveDiscounts({ search }))
	}, [search])

	useEffect(() => {
		dispatch(fetchActiveProducts({}))
		dispatch(fetchDeletedProducts())
	}, [])

	const discounts: Discount[] = useSelector((s: any) => s.discount.list)
	const { role } = useSelector((s: any) => s.activeUser.data)
	const isOwner = role =='owner'


	return (
		<div className="Discounts">
			<AddDiscountPopup
				setVisible={setAddPopupOpen}
				visible={addPopupOpen}
			/>
			<ConfirmDeletePopup
				setVisible={setDeletePopupOpen}
				visible={deletePopupOpen}
				onComplete={() => {
					setDeletePopupOpen(false)
				}}
			/>
			<MainContainer>
				<div className="flexY header">
					<h1 className='title'>
						Discount Code
					</h1>
					<div className="SearchBox">
						<FontAwesomeIcon icon={faSearch} color="#b7b7b7" className="SearchIcon" />
						<input
							type="text"
							className="input SearchInput"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							placeholder="Search for a discount code here"
						/>
					</div>
				</div>
				<div className="DiscountContainer">
					{discounts.map((discount) => {
						return (
							<div className="DiscountCardContainer" key={discount.discountID}>
								<DiscountCard
									discount={discount}
									onDelete={() => setDeletePopupOpen(true)}
									productsMap={productsMap}
								/>
							</div>
						)
					})}
				</div>
				<div className="ActionContainer">
					{ isOwner ? (
						<button className="button neutral plain viewAll" onClick={() => setAddPopupOpen(true)}>
							+Add a Discount Code
						</button>
					) : null}
				</div>
			</MainContainer>
		</div>
	)
}
