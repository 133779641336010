import React, { useState, SetStateAction, useEffect, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FreeButton, MenuButton } from 'react-ui-scaffold'
import Popup from '../../../Components/Popup/Popup'
import { updateDiscount, updateDiscountStatus } from '../../../Redux/Slices/discountSlice'
import './EditDiscountPopup.css'
import DiscountCustomersPopup from './Support/DiscountCustomersPopup/DiscountCustomersPopup'
import DiscountEditForm from './Support/DiscountEditForm'
import DiscountFullEditForm from './Support/DiscountFullEditForm'
import DiscountOrdersPopup from './Support/DiscountOrdersPopup/DiscountOrdersPopup'

interface EditDiscountPopupProps {
    visible: boolean
    setVisible: React.Dispatch<SetStateAction<boolean>>
	onDelete?: () => void
}

export default function EditDiscountPopup({ visible, setVisible, onDelete }: EditDiscountPopupProps) {
	const dispatch = useDispatch()

	const { role } = useSelector((s: any) => s.activeUser.data)
	const isOwner = role =='owner'
	const activeDiscount = useSelector((s: any) => s.discount.activeDiscount)

	const [error, setError] = useState(null)
	const [data, setData] = useState<any>({})
	const [loading, setLoading] = useState<boolean>(false)
	const [fullEditMode, setFullEditMode] = useState<boolean>(false)
	const [ordersPopupVisible, setOrdersPopupVisible] = useState<boolean>(false)
	const [customersPopupVisible, setCustomersPopupVisible] = useState<boolean>(false)

	useEffect(() => {
		if (visible) {
			if (activeDiscount) {
				const amount = activeDiscount.type == 'Percent' ? activeDiscount.amount : ((activeDiscount.amount || 0) / 100).toFixed(2)
				setData({
					...activeDiscount,
					amount,
				})
			}
		} else {
			setLoading(false)
			setError(null)
			setFullEditMode(false)
		}
	}, [visible, activeDiscount])

	const changeHandler = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setData({ ...data, [e.target.name]: e.target.value })
	}

	const onSubmit = () => {
		setLoading(true)
		setError(null)
		const requestCallback = (err: any) => {
			setLoading(false)
			if (err) {
				return setError(err)
			}
			setVisible(false)
		}
		if (fullEditMode) {
			const amount = (parseFloat(data.amount) * (data.type == 'Percent' ? 1 : 100)).toFixed(0)
			dispatch(updateDiscount({
				data: {
					...data,
					amount
				},
				callback: requestCallback
			}))
		} else {
			dispatch(updateDiscountStatus({
				data: {
					status: data.status,
					discountID: data.discountID
				},
				callback: requestCallback
			}))
			
		}
	}

	if (!visible || !activeDiscount) {
		return null
	}

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title={fullEditMode ? 'Edit Code' : activeDiscount.code}
			action={isOwner && !fullEditMode ? (
				<EditMenuButton setFullEditMode={setFullEditMode} onDelete={onDelete} />
			) : null}
			className='EditDiscountPopup thinContainer'
		>
			<DiscountCustomersPopup
				visible={customersPopupVisible}
				setVisible={setCustomersPopupVisible}
			/>
			<DiscountOrdersPopup
				visible={ordersPopupVisible}
				setVisible={setOrdersPopupVisible}
			/>
			<div className="story error">
				{error}
			</div>

			{fullEditMode ? (
				<DiscountFullEditForm
					data={data}
					setData={setData}
					changeHandler={changeHandler}
				/>
			) : (
				<DiscountEditForm
					data={data}
					changeHandler={changeHandler}
					setOrdersPopupVisible={setOrdersPopupVisible}
					setCustomersPopupVisible={setCustomersPopupVisible}
				/>
			)}

			<div className="wide DoneButtonContainer">
				<FreeButton
					onClick={() => onSubmit()}
					loading={loading}
				>
					Save Changes
				</FreeButton>
			</div>
		</Popup>
	)
}

function EditMenuButton({ setFullEditMode, onDelete }: { setFullEditMode: (v: boolean) => void, onDelete?: () => void }) {
	return (
		<MenuButton
			menuContent={
				<>
					<button
						className="button wide"
						onClick={() => {
							setFullEditMode(true)
						}}
					>
						Edit Code
					</button>
					<button
						className="button wide"
						onClick={() => {
							onDelete && onDelete()
						}}
					>
						Delete Code
					</button>
				</>
			}
		>
			<button className="button neutral plain viewAll" >
				More
			</button>
		</MenuButton>
	)
}
