import moment from 'moment'
import React, { useState, SetStateAction, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Popup from '../../../../../Components/Popup/Popup'
import { saveLocation } from '../../../../../Redux/Slices/activeUserSlice'
import { fetchOrdersByDiscount } from '../../../../../Redux/Slices/discountSlice'
import { Order, statusBadgeColorMap } from '../../../../../Redux/Slices/orderSlice'

import './DiscountOrdersPopup.css'

interface DiscountOrdersPopupProps {
	visible: boolean
	setVisible: React.Dispatch<SetStateAction<boolean>>
}
const pageSize = 50

export default function DiscountOrdersPopup({ visible, setVisible }: DiscountOrdersPopupProps) {
	const dispatch = useDispatch()
	const history = useHistory()
	const locations = useSelector((s: any) => s.activeUser.data?.availableLocations || [])
	const [page, setPage] = useState(1)
	const [lastFetchedPage, setLastFetchedPage] = useState(0)

	const _fetchOrders = () => {
		dispatch(fetchOrdersByDiscount({ discountID: activeDiscount.discountID, page }))
		setLastFetchedPage(page)
	}

	const activeDiscount = useSelector((s: any) => s.discount.activeDiscount)

	useEffect(() => {
		if (visible && lastFetchedPage != page && activeDiscount.discountID) {
			_fetchOrders()
		}
	}, [visible, activeDiscount.discountID, page])

	useEffect(() => {
		if (activeDiscount.discountID) {
			setPage(1)
		}
	}, [activeDiscount.discountID])

	const goToOrder = (order: Order) => {
		const location = locations.find((location: any) => location.locationID == order.dropOffLocation)
		dispatch(saveLocation(location))
		history.push(`/Orders/${order.orderID}`)
	}

	if (!visible) {
		return null
	}

	return (
		<Popup
			visible={visible}
			setVisible={setVisible}
			title={`Orders - ${activeDiscount.code}`}
			className='DiscountOrdersPopup'
		>
			
			<div className="story body">
				<div className="CustomerCount hemi">{activeDiscount.orderCount} Orders</div>
				<table className='wide CustomersTable'>
					<thead>
						<tr>
							<th>Order #:</th>
							<th>Date Placed:</th>
							<th>Order Status:</th>
						</tr>
					</thead>
					<tbody>
						{activeDiscount.orders?.map((order: Order) => {
							const numberOfItems = order.contents.reduce((accum: number, element: any) => {
								return accum + element.quantity
							}, 0)
							return (
								<tr key={order.orderID}>
									<td>
										<div
											className="button neutral plain"
											onClick={() => {
												goToOrder(order)
											}}
										>
											Order #{order.shortID} ({numberOfItems} Items)
										</div>
									</td>
									<td>{moment(order.placedDate).format('MMM D, YYYY')}</td>
									<td style={{ color: statusBadgeColorMap[order.status] }}>{order.status}</td>
								</tr>
							)
						})}
					</tbody>
				</table>
				{pageSize * page < (activeDiscount?.orderCount || 0) ? (
					<div className="LoadMore" onClick={() => setPage(page + 1)}>Load more</div>
				) : null}
			</div>
			
		</Popup>
	)
}
