import React, { ChangeEvent, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DiscountStatuses } from '../../../../@types/Discount'
import { Product } from '../../../../Redux/Slices/productsSlice'

interface DiscountEditFormProps {
	data: any,
	changeHandler: (v: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
	setOrdersPopupVisible: (v: boolean) => void,
	setCustomersPopupVisible: (v: boolean) => void
}

export default function DiscountEditForm({ data, changeHandler, setOrdersPopupVisible, setCustomersPopupVisible }: DiscountEditFormProps) {
	const activeDiscount = useSelector((s: any) => s.discount.activeDiscount)
	const products: Product[] = useSelector((s: any) => s.product.list)
	const deletedProducts: Product[] = useSelector((s: any) => s.product.deletedList)

	const productsMap = useMemo(() => {
		const map: { [key: string]: { deleted: boolean, product: Product } } = {}
		products.map((product) => {
			map[product.productID] = { deleted: false, product }
		})
		deletedProducts.map((product) => {
			map[product.productID] = { deleted: true, product }
		})
		return map
	}, [products, deletedProducts])
	const discountAmountMessage = useMemo(() => {
		if (!activeDiscount) {
			return ''
		}
		if (activeDiscount.type == 'Percent') {
			return `${activeDiscount.amount}% off`
		}
		return `$${(activeDiscount.amount / 100).toFixed(2)} CAD off`
	}, [activeDiscount])
	return (
		<div className="story body">
			<div className="subStory half" >
				<label>Discount:</label>
				<div>
					{discountAmountMessage}
				</div>
			</div>
			<div className="subStory half" >
				<label>Applies to:</label>
				{
					activeDiscount.products.length ? (
						<div className="ProductsList">
							{activeDiscount.products.map((productID: number) => {
								return (
									<div
										key={productID}
										className={productsMap[productID]?.deleted ? 'ArchivedProduct' : ''}
									>
										{productsMap[productID]?.product.name}
									</div>
								)
							})}
						</div>
					) : (
						<span>All Items</span>
					)
				}
			</div>
			<div className="subStory half" >
				<label>Usage:</label>
				<div>
					{activeDiscount.usage}
				</div>
			</div>
			<div className="subStory half" >
				<label>Status:</label>
				<select
					name='status'
					className='input wide'
					value={data.status || ''}
					onChange={changeHandler}
				>
					<option value={DiscountStatuses.Active}>
						{DiscountStatuses.Active}
					</option>
					<option value={DiscountStatuses.Inactive}>
						{DiscountStatuses.Inactive}
					</option>
				</select>
			</div>
			<div className="subStory half" >
				<label>Number of Orders:</label>
				<div>
					{activeDiscount.orderCount}
				</div>
				<button className="button neutral plain viewButton" onClick={() => setOrdersPopupVisible(true)}>
					View Orders
				</button>
			</div>
			<div className="subStory half" >
				<label>Number of Customers:</label>
				<div>
					{activeDiscount.customerCount}
				</div>
				<button className="button neutral plain viewButton" onClick={() => setCustomersPopupVisible(true)}>
					View Customers
				</button>
			</div>
		</div>
	)
}
