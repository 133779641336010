import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Discount } from '../../../../@types/Discount'
import EditDiscountPopup from '../../../../Popups/DiscountPopups/EditDiscountPopup/EditDiscountPopup'
import { fetchDiscount } from '../../../../Redux/Slices/discountSlice'
import './DiscountCard.css'

export default function DiscountCard({ discount, onDelete, productsMap }: { discount: Discount, onDelete?: () => void, productsMap: any  }) {
	const dispatch = useDispatch()
	const [editPopupOpen, setEditPopupOpen] = useState<boolean>(false)

	const discountAmountMessage = useMemo(() => {
		if (discount.type == 'Percent') {
			return `${discount.amount}% off`
		}
		return `$${(discount.amount / 100).toFixed(2)} CAD off`
	}, [discount])


	return (
		<>
			<EditDiscountPopup
				setVisible={setEditPopupOpen}
				visible={editPopupOpen}
				onDelete={onDelete}
			/>
			<div
				className="DiscountCard"
				onClick={() => {
					setEditPopupOpen(true)
					dispatch(fetchDiscount(discount))
				}}
			>
				<div className="DiscountCardTitle hemi">{discount.code}</div>
				<div>
					Discount:&nbsp;
					<span className="DiscountCardValue">{discountAmountMessage}</span>
				</div>
				<div>
					Status:&nbsp;
					<span className={`DiscountCardValue ${discount.status}`}>{discount.status}</span>
				</div>
				<div>
					Applies to:&nbsp;
					<div className="DiscountCardValue">
						{
							discount.products.length ? (
								<div className="ProductsList">
									{discount.products.map((productID: number) => {
										return (
											<div
												key={productID}
												className={productsMap[productID]?.deleted ? 'ArchivedProduct' : ''}
											>
												-  {productsMap[productID]?.product.name}
											</div>
										)
									})}
								</div>
							) : (
								<span>All Items</span>
							)
						}
					</div>
				</div>
				<div>
					Usage:&nbsp;
					<span className="DiscountCardValue">{discount.usage}</span>
				</div>
			</div>
		</>
	)
}
