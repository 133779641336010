import { createSlice } from '@reduxjs/toolkit'

const initialState: { screenSize: string, hasPopup: boolean, popupMap: { [key: string]: boolean }} = {
	screenSize: '',
	hasPopup: false,
	popupMap: {}
}
const viewSlice = createSlice({
	name: 'view',
	initialState: initialState,
	
	reducers: {
		setScreenSize: (state, { payload }) => {
			state.screenSize = payload
		},
		openPopup: (state, { payload }) => {
			if (!state.popupMap[payload]) {
				state.popupMap[payload] = true
			}
			state.hasPopup = Boolean(Object.keys(state.popupMap).length)
		},
		closePopup: (state, { payload }) => {
			if (state.popupMap[payload]) {
				delete state.popupMap[payload]
			}
			state.hasPopup = Boolean(Object.keys(state.popupMap).length)
		}
	}
	
})

export const { setScreenSize, openPopup, closePopup } = viewSlice.actions
export default viewSlice.reducer
